@import 'styles/color.scss';

a {
  text-decoration: none;
}

ol,
ul {
  list-style: none;
}

#sidebar {
  background: $Primary-300;
  height: 100%;
  grid-area: sidebar;
  overflow-y: auto;
  // padding: 20px;
  box-shadow: 0 2px 16px hsla(0, 0%, 0%, 0.1);
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.sidebar__title {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: center;
  color: $Shade-200;
  margin: 1rem 0.5rem;
}

.sidebar__img {
  display: flex;
  align-items: center;
}

.sidebar__text {
  font-size: 1.3rem;
  letter-spacing: 1px;
  word-spacing: 1px;
  text-align: center;
  color: #ffffff;
}

.sidebar__title > div > img {
  width: 75px;
  object-fit: contain;
}

.sidebar__title > div > h1 {
  font-size: 18px;
  display: inline;
}

.sidebar__title > i {
  font-size: 18px;
  display: none;
}

.sidebar__menu > h2 {
  /* color: #3ea175; */
  color: $Shade-200;
  font-size: 16px;
  margin-top: 18px;
  margin-bottom: 6px;
  padding: 0 10px;
  font-weight: 700;
}

.sidebar__link {
  color: #f3f4f6;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 5px;
}

.active_menu_link {
  /* background: rgba(62, 161, 117, .3); */
  /* color: #3ea175; */
  // 노랑색일때
  // background: rgba(255, 170, 0, .3);
  color: $Secondary-300;
  // 연두색일때
  background: rgba(107, 178, 31, 0.3);
}

.active_menu_link a {
  /* color: #3ea175 !important; */
  color: $Secondary-300 !important;
}

.sidebar__link > a {
  text-decoration: none;
  color: #a5aaad;
  font-weight: 700;
}

.sidebar__link > i {
  margin-right: 10px;
  font-size: 18px;
}

.sidebar-responsive {
  display: inline !important;
  z-index: 9999 !important;
  left: 0 !important;
  position: absolute;
}
