.basicWapper {
  width: 100%;
  height: 100%;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
}

.loader {
  // 정중앙에
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
