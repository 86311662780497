.breadcrumb {
  width: 100%;
}

.breadcrumb .inner {
  display: flex;
  align-items: center;
  //height: 50px;
  height: 10px;
}

.breadcrumb .bcmb_elm {
  position: relative;
  display: inline-block;
  margin-right: 37px;
  font-size: 16px;
  color: #666;
}

.breadcrumb .bcmb_elm:after {
  content: '';
  position: absolute;
  top: 50%;
  right: -20px;
  width: 7px;
  height: 13px;
  margin-top: -6px;
  background: url(../../assets/images/common/icon_bcmb_arr.png) no-repeat
    center/100%;
}

.breadcrumb .bcmb_home {
  width: 16px;
  height: 16px;
  font-size: 0;
  background: url(../../assets/images/common/icon_home.png) no-repeat
    center/100%;
}

.breadcrumb .bcmb_on {
  color: #222;
  font-weight: 500;
}

.breadcrumb .bcmb_on:after {
  display: none;
}
