@import 'styles/color';

:root {
  // --font-family: 'Noto Sans KR';
  width: 100%;
  height: 100vh;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  min-width: 1007px;
  font-size: 16px;
  font-family: 'play', 'Noto Sans KR', 'Malgun Gothic', '맑은 고딕', arial,
    sans-serif;
  color: #222;
  letter-spacing: -0.5px;
  word-wrap: break-word;
  word-break: break-all;
}

.notoSansKR {
  font-family: 'Noto Sans KR', sans-serif;
}

.play {
  font-family: 'play', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

//  default layout
#default-layout {
  height: 100vh;
}

//  header layout
#header.ant-layout-header {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $Shade-50;
  color: $Shade-600 !important;
  border-bottom: 1px solid lightgray;
}

#header .title {
  margin-left: 30px;
}

/*추가*/
#header .header-text {
  margin-left: 20vw;
  background-color: $Secondary-500;
  padding: 10px 20px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}
/*추가 end */

#header .header-right {
  color: $Shade-600;
  display: flex;
  font-size: 28px;
  padding-right: 30px;
}

#header .header-right > .icon {
  border-radius: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  background: $Shade-50;
  box-shadow:
    2px 2px 5px #d9d9d9,
    -2px -2px 5px #ffffff;
  padding: 7px;
  cursor: pointer;
}

//#header .header-right > .icon:hover {
//  transform: scale(1.05);
//  transition: all 300ms ease;
//}

#header .header-right > div:nth-child(2) {
  margin-left: 20px;
}

/* hr */
hr.cm_line {
  width: 100%;
  height: 2px;
  margin: 25px 0;
  padding: 0;
  border: 0;
  background-color: #ddd;
}

/* title */
.cm_title1 {
  margin-bottom: 35px;
  font-size: 34px;
  font-weight: 600;
  line-height: 1.3;
  color: #222;
}

.cm_title2 {
  margin-bottom: 30px;
  font-size: 26px;
  font-weight: 600;
  line-height: 1.3;
  color: #222;
}

.cm_title3 {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.3;
  // color: #2278e7;
  color: $Secondary-300;
}

.cm_title4 {
  margin-bottom: 15px;
  font-size: 28px;
  font-weight: 600;
  line-height: 1.3;
  color: #222;
}

.cm_title5 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: #222;
}

.cm_title5 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: #222;
}

.btn_title_more {
  display: inline-block;
  line-height: 1.4;
  font-size: 14px;
  padding-right: 15px;
  color: #222;
  // background: url(../assets/images/common/arr_more.png) no-repeat right
  //   center/7px;
}

/* ********************************************************************************* * 02) Button/icon */
.btn {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 40px;
  //height 45px
  //padding: 5px 25px;
  line-height: 1.2;
  color: #555;
  //font-size: 16px;
  font-weight: 500;
  text-align: center;
  word-break: keep-all;
  border: 1px solid #b4b4b4;
  background-color: $Shade-50;
  border-radius: 4px;
  font-family: 'Noto Sans KR', 'Malgun Gothic', '맑은 고딕', arial, sans-serif;
  letter-spacing: -0.5px;
}

.btn_sm {
  height: 35px;
  padding: 5px 15px;
  font-size: 14px;
  border-radius: 3px;
}

.btn_lg {
  height: 50px;
  padding: 5px 25px;
  font-size: 17px;
}

.btn_fix {
  min-width: 120px;
}

.cm_btn1 {
  color: $Shade-50;
  background-color: $Primary-300;
  border-color: $Primary-300;
}

.cm_btn2 {
  color: #222;
  background-color: #f4f4f4;
  border-color: #f4f4f4;
}

.cm_btn3 {
  color: $Primary-300;
  background-color: $Shade-50;
  border-color: $Primary-300;
}

.cm_btn4 {
  color: $Primary-300;
  background-color: #eff5fd;
  border-color: #d6e4f6;
}

.cm_btn5 {
  color: $Shade-50;
  background-color: $Primary-500;
  border-color: $Primary-500;
}

/* 추가 */
.wide_btn {
  height: 80px;
  width: 120px;
  font-weight: 600;
  border-width: 2px;
}

//.cm_btn1:hover, .cm_btn5:hover {
//  color: #ffffff !important;
//  background-color: $Primary-600 !important;
//}

/* 추가 끝 */

.cm_btn6 {
  color: #666;
  background-color: #f7f7f7;
  border-color: #e0e0e0;
}

.btn_text {
  padding: 0;
  font-size: 14px;
  color: #666;
  border-color: transparent;
  background-color: transparent;
}

.btn .btn_icon {
  position: relative;
  display: inline-block;
}

.btn .btn_icon:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
}

.btn_icon_left .btn_icon {
  padding-left: 20px;
}

.btn_icon_left .btn_icon:after {
  left: 0;
  background-position: left center;
}

.btn_icon_right .btn_icon {
  padding-right: 25px;
}

.btn_icon_right .btn_icon:after {
  right: 0;
  background-position: right center;
}

.btn .icon_file_og:after {
  width: 15px;
  height: 15px;
  // background-image: url(../assest/images/common/icon_file_og.png);
  background-size: 100%;
}

.btn_search {
  background-image: url(../assets/images/common/icon_search.png);
  background-size: 82%;
}

.btn_search_sm .btn_icon:after {
  width: 15px;
  height: 15px;
  background-image: url(../assets/images/common/icon_search_sm.png);
}

.btn_refresh .btn_icon:after {
  width: 15px;
  height: 14px;
  background-image: url(../assets/images/common/icon_refresh.png);
}

.btn_download .btn_icon:after {
  width: 16px;
  height: 16px;
  background-image: url(../assets/images/common/icon_download.png);
}

.radius_none {
  border-radius: 0;
}

.radius_round {
  border-radius: 50px;
}

.radius_circle {
  border-radius: 50%;
}

.btn_more {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 30px;
  font-size: 16px;
}

.btn_more:after {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 19px;
  height: 19px;
  margin-top: -9px;
  background: url(../assets/images/common/icon_more.png) no-repeat center/100%;
}

/* sub text */
.cm_text1 {
  font-size: 22px;
  color: #222;
  line-height: 1.5;
}

.cm_text2 {
  font-size: 16px;
  color: #555;
  line-height: 1.5;
}

.cm_text_date {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
  font-family: 'play', 'Noto Sans KR', sans-serif;
  line-height: 1;
  transition: color 0.2s;
}

.cm_text_date:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 15px;
  margin-right: 10px;
  background: url(../assets/images/common/icon_calendar.png) no-repeat
    center/100%;
  transition: background-image 0.2s;
}

.cm_text_loca {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
  font-family: 'play', 'Noto Sans KR', sans-serif;
  line-height: 1;
  transition: color 0.2s;
}

.cm_text_loca:before {
  content: '';
  display: inline-block;
  width: 14px;
  height: 16px;
  margin-right: 10px;
  background: url(../assets/images/common/icon_location.png) no-repeat
    center/100%;
  transition: background-image 0.2s;
}

.cm_text_time {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1899ff; // #555;
  font-family: 'play', 'Noto Sans KR', sans-serif;
  line-height: 1;
  transition: color 0.2s;
}

.cm_text_time:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  background: url(../assets/images/common/icon_clock.png) no-repeat center/100%;
  transition: background-image 0.2s;
}

.cm_text_writer {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
  font-family: 'play', 'Noto Sans KR', sans-serif;
  line-height: 1;
  transition: color 0.2s;
}

.cm_text_writer:before {
  content: '';
  display: inline-block;
  width: 17px;
  height: 17px;
  margin-right: 10px;
  margin-top: -1px;
  background: url(../assets/images/common/icon_writer.png) no-repeat center/100%;
  transition: background-image 0.2s;
}

.text_through {
  text-decoration: line-through;
}

.border {
  border: 1px solid #ddd;
}

.border_t {
  border-top: 1px solid #ddd;
}

.border_r {
  border-right: 1px solid #ddd;
}

.border_b {
  border-bottom: 1px solid #ddd;
}

.border_l {
  border-left: 1px solid #ddd;
}

/* 공통 모달 */

.cm_modal .ant-modal-content {
  padding: 0px;
}

.cm_modal .ant-modal-header {
  flex-shrink: 0;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  height: 90px;
  padding: 20px 90px 25px 50px;
}

.cm_modal .ant-modal-body {
  flex-shrink: 0;
  height: 100%;
  max-height: 85vh;
  padding: 0 50px 20px;
  text-align: left;
  overflow-y: auto;
}

.cm_modal .ant-modal-footer {
  flex-shrink: 0;
  height: 80px;
  padding: 0 50px 25px;
}

.cm_modal .ant-modal-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.3;
  color: #222;
  text-align: left;
}

.cm_modal .ant-modal-close {
  position: absolute;
  top: 25px;
  right: 30px;
  width: 50px;
  height: 50px;
  font-size: 0;
  background: url(../assets/images/common/icon_close.png) no-repeat center;
  z-index: 5;
}

//  menu

/* ********************************************************************************* * 04) tab */
.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: $Primary-10 !important;
}

.ant-tabs .ant-tabs-ink-bar {
  background: $Primary-10 !important;
}

.cm_tab2 .ant-tabs-nav-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.cm_tab2 .ant-tabs-nav-list .ant-tabs-tab {
  flex-basis: 150px;
  margin: 0;
  align-items: center;
  justify-content: center;
  height: 45px;
  margin-right: 15px;
  margin-bottom: 5px;
  padding: 0 25px;
  font-size: 17px;
  color: #222;
  border: 2px solid transparent;
  border-radius: 5px;
  transition:
    border-color 0.2s,
    color 0.2s;
  box-sizing: border-box;
}

.cm_tab2 .ant-tabs-nav-list .ant-tabs-tab:hover,
.cm_tab2 .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  color: $Primary-10;
  font-weight: 500;
  border-color: $Primary-10;
  border-radius: 5px;
}

.cm_tab2 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  display: none;
}

.cm_tab3 .ant-tabs-nav-list {
  display: flex;
  width: 100%;
  border-bottom: 1px solid #e5e5e5;
  border-top: 1px solid #cacaca;
}

.cm_tab3 .ant-tabs-nav-list .ant-tabs-tab {
  flex: 1;
  margin: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  padding: 5px 15px;
  font-size: 17px;
  color: #222;
  transition: color 0.2s;
  word-break: keep-all;
  text-align: center;
}

.cm_tab3 .ant-tabs-nav-list .ant-tabs-tab:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  width: 1px;
  height: 18px;
  margin-top: -10px;
  background-color: #ddd;
}

.cm_tab3 .ant-tabs-nav-list .ant-tabs-tab:nth-last-of-type(2)::before {
  display: none;
}

.cm_tab3 .ant-tabs-nav-list .ant-tabs-tab:after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: $Primary-10;
  opacity: 0;
  transition: opacity 0.2s;
}

.cm_tab3 .ant-tabs-nav-list .ant-tabs-tab:hover,
.cm_tab3 .ant-tabs-nav-list .ant-tabs-tab.ant-tabs-tab-active {
  color: $Primary-10;
  font-weight: 600;
}

.cm_tab3 .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
  margin: 0;
}

// Table
#table .ant-table-cell-row-hover {
  background-color: #f5f7fa !important;
}

// Datasheet
#datasheet {
  .row-deleted .dsg-cell {
    /* Red */
    background: #fff1f0;
  }

  .row-created .dsg-cell {
    /* Green */
    background: #f6ffed;
  }

  .row-updated .dsg-cell {
    /* Orange */
    background: #fff7e6;
  }

  .dsg-context-menu-item {
    display: flex;
    align-items: center;
    padding: 3px 10px !important;
    cursor: default;

    svg {
      margin-right: 10px;
    }
  }
}

#datasheet .dsg-add-row {
  display: flex;
  justify-content: space-between;
  background: #fafafa;
  border-top: none;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  color: black;
}

#datasheet .dsg-commit-row {
  display: flex;
}

// menu

#side-menu.ant-menu {
  background: $Primary-300;
  padding-bottom: 30px;
}

// menu text 전체
#side-menu.ant-menu .ant-menu-submenu,
#side-menu.ant-menu .ant-menu-item {
  color: $Shade-200;
}

// depth 1 활성화
#side-menu
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu-submenu-title,
#side-menu .ant-menu-item.ant-menu-item-selected,
#side-menu .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-selected,
#side-menu .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open {
  color: $Shade-50;
  border-color: $Primary-250;
  background-color: $Primary-250;
  border-radius: 0 !important;
}

// depth 2 배경
#side-menu
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu {
  background-color: $Shade-50;
}

// depth 2 의 li
#side-menu
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu
  .ant-menu-item {
  color: $Primary-500;
  border-color: $Primary-500;
}

// depth 2 활성화
#side-menu
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu
  .ant-menu-item.ant-menu-item-selected {
  background: transparent;
  color: $Primary-500;
  border-color: $Primary-500;
}

// #side-menu .ant-menu-item.ant-menu-item-selected {
//   background-color: $Primary-500;
//   border-radius: 0px !important;
//   margin: 0px !important;
// }

#side-menu
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu
  .ant-menu-item.ant-menu-item-selected
  > span
  > a {
  border-bottom: 1px solid $Primary-500;
  padding-bottom: 3px;
}

#side-menu
  .ant-menu-submenu.ant-menu-submenu-inline.ant-menu-submenu-open
  > .ant-menu
  .ant-menu-item.ant-menu-item-active
  > span
  > a {
  border-bottom: 1px solid $Primary-500;
  padding-bottom: 3px;
}

.ant-menu .ant-menu-sub.ant-menu-vertical {
  background: $Primary-300;
  color: $Shade-50;
}

.ant-menu
  .ant-menu-sub.ant-menu-vertical
  > .ant-menu-item.ant-menu-item-active
  > span
  > a {
  color: $Shade-50;
  border-bottom: 1px solid $Shade-50;
  padding-bottom: 3px;
}

.ant-menu
  .ant-menu-sub.ant-menu-vertical
  > .ant-menu-item.ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu
  .ant-menu-sub.ant-menu-vertical
  > .ant-menu-item.ant-menu-item-selected
  > span,
.ant-menu
  .ant-menu-sub.ant-menu-vertical
  > .ant-menu-item.ant-menu-item-active
  > span {
  color: $Shade-50;
}

.ant-tooltip-inner {
  background: $Primary-300 !important;
}
