.header {
  width: 100%;
}

.trigger {
  svg {
    margin-left: 20px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
