.page {
  display: flex;
  height: 100vh;
  background-color: #e7ecf1;
  justify-content: center;
  align-content: center;
}

.loginForm {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 400px;
  margin: auto;
  background: rgba(255, 255, 255, 0.9);
  height: 350px;
  border-radius: 10px;
}

.loginHeader {
  text-align: center;
  margin-bottom: 20px;
}
