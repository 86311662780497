$Primary-10: #367cb8;
$Primary-50: #eff5fd;
$Primary-100: #d6e4f6;
$Primary-200: #367cb8;
$Primary-250: #375e87;
$Primary-300: #163244;
$Primary-500: #000000;

$Secondary-50: #13c2c2;
$Secondary-60: #08979c;
$Secondary-70: #006d75;
$Secondary-80: #00474f;
$Secondary-90: #002329;
$Secondary-100: #6666ff;
$Secondary-200: #3d59ab;
$Secondary-300: #1677ff;
$Secondary-400: #2e37fe;
$Secondary-500: #060761;

$Emphasis-200: #ff8000;
$Emphasis-300: #ff6013;

$Shade-50: #ffffff;
$Shade-100: #f9f9f9;
$Shade-200: #f7f7f7;
$Shade-300: #c6c6c6;
$Shade-400: #7c7c7c;
$Shade-500: #313131;
$Shade-600: #191919;

$Background: #f3f4f6;

$UserApp-10: #FFE6EA;
$UserApp-50: #FFB3C0;
$UserApp-100: #ff8da1;
$UserApp-200: #ff748c;
$UserApp-300: #ff5a77;

