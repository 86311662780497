@import 'styles/color';

// ant design
.ant-btn {
  box-shadow: none;
  height: 36px;
}

.ant-input:focus {
  box-shadow: none;
}

.ant-table {
  border: 2px solid $Shade-300;
}

.test-type-tag.ant-tag-has-color {
  background-color: $Secondary-200 !important;
}

.emotion-field-tag.ant-tag-has-color {
  background-color: $Shade-400 !important;
}

.answer-table-container .ant-table {
  border: none;
}

// Layout
.layout-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.three-to-two {
  grid-template-columns: 3fr 2fr;
}

.two-to-three {
  grid-template-columns: 2fr 3fr;
}

.one-to-third {
  grid-template-columns: 1fr 1fr 1fr;
}

// Header
.chat-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 10px 0;
}

.content-header {
  display: flex;
  align-items: center;
  margin: 10px 0 20px 5px;
  position: relative;
}

.content-header h2 {
  color: $Primary-300;
}

.new-btn {
  margin-left: 40px;
}

.btn {
  margin-left: 5px;
}

.new-btn::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  width: 1px;
  height: 30px;
  background-color: $Shade-300;
  transform: translateY(-50%);
  margin-left: -20px;
}

// Section
.centered-outer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-container {
  min-height: 40vh;
  background: #fff;
  padding: 25px;
  margin-bottom: 20px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.section-content {
  padding: 25px 10px;
}

//Button, Menu

.save-btn-group {
  margin-bottom: 12px;
}

.save-btn, .cancel-btn, .preview-btn, .delete-btn {
  display: inline-flex;
  align-items: center;
}

.cancel-btn, .preview-btn, .delete-btn {
  margin-left: 5px;
}

.save-btn {
}

.btn-with-text {
  display: flex;
  align-items: center;
}

.btn-with-text span {
  margin-left: 5px;
}

.menu-item-text {
  margin-left: 8px;
}

.icon-btn {
  width: 50px !important;
  height: 45px !important;
}

.btn-group button {
  margin-right: 5px;
}

// Modal
.setting-icon-container:hover {
  cursor: pointer;
}

.common-modal .ant-modal-header {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.common-modal-content {
  padding: 20px 10px 10px 10px;
}

.register-btn-outer {
  margin-top: 50px;
}

.confirm-modal-content {
  padding: 5px 20px;
}

.confirm-modal-footer {
  display: flex;
  justify-content: flex-end;
}

.confirm-modal-footer .ant-btn {
  margin-left: 5px;
}

// Card
.common-card {
  border: 1px solid #7c7c7c;
  margin-bottom: 8px;
}

.common-card:focus-within {
  cursor: pointer;
  border: 2px solid $Secondary-300;
}

.common-card.editing {
  border: 2px solid $Secondary-300;
}

.common-card-content {
  padding: 0 28px;
}

.card-list-title {
  color: $Primary-200;
  font-size: 1.5em;
  margin-bottom: 10px;
}

/*Question Card*/
.qn-form-list-container {
  display: flex;
}

.qn-form-list {
  max-width: 40vw;
}

.qn-card-title {
  display: flex;
}

.qn-header {
  display: flex;
}

.qn-number {
  margin-right: 10px;
  font-size: 1.4em;
  font-weight: 600;
}

.qn-chat-group {
  padding: 10px 40px 40px 10px;
  border-radius: 5px;
  border: 1px solid $Shade-300;
  box-shadow: 1px 1px 1px 1px $Shade-300;
}

.qn-chat-btn-outer {
  margin: 20px 0 8px 0;
  display: flex;
  justify-content: flex-end;
}

.qn-chat-btn {
  height: 100%;
  background: $Shade-200;
  border-color: $Shade-300;
  color: $Shade-500;
}

.qn-chat-btn.plus {
  padding-right: 40px;
  padding-left: 40px;
  box-shadow: 1px 1px 1px 1px $Shade-300;
}

.qn-chat-text-input-group {
  display: flex;
  margin-bottom: 8px;
}

/*랜덤말풍선*/
.rs-bubble-card .qn-chat-group {
  min-height: 30vh;
}

.rs-bubble-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0 8px 0;
}

.rs-bubble-footer {
  display: flex;
  justify-content: flex-end;
  margin: 8px 0 20px 0;
}

.rs-bubble-card .qn-chat-btns {
  display: flex;
  align-items: center;
}

.rs-bubble-card .qn-chat-btns .ant-input-search {
  margin: 0 5px;
}

.rs-bubble-card .qn-chat-btns input {
  height: 36px;
  width: 150px;
}

.rs-bubble-card .qn-chat-btns .ant-input-group-addon .ant-btn-primary {
  background: $Shade-200;
  border-color: $Shade-300;
  color: $Shade-500;
  height: 36px;
}

.rs-bubble-card .ant-form-item.qn-chat-input-form {
  margin: 0 10px 0 0;
}

.rs-bubble-card .qn-chat-input-label {
  margin-right: 10px;
}

.rs-bubble-card .qn-chat-input {
  width: 120px;
  margin-right: 10px;
  margin-left: 10px;
}

.rs-bubble-card .qn-chat-add-input {
  width: 100px;
  margin-right: 5px;
  margin-left: 5px;
}

.rs-bubble-card .qn-chat-text-input-group {
  margin-bottom: 20px;
}

.rs-bubble-card .qn-chat-btn.plus {
  padding: 4px 15px;
  height: 36px;
}

.qn-chat-text-input-group > div > .qn-chat-btn.minus {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

/*랜덤말풍선 end */

.qn-chat-text-input-group > div > .qn-chat-btn.minus {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.qn-chat-text-input-group .ant-form-item {
  margin-bottom: 0;
  flex: 1;
}

.qn-chat-interval-input {
  width: 30%;
  float: right;
}

.qn-chat-btn.plus:hover.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
}

.qn-textarea-outer {
  display: flex;
}

.qn-textarea-outer textarea {
  flex: 1;
}

.qn-input::placeholder, #coForm .ant-form-item .ant-input::placeholder {
  text-align: center;
}

.qn-add-btn-group {
  margin-top: 8px;
}

// Structuring
.importing-container {
  border: 1px dashed #7c7c7c;
  padding: 20px;
  border-radius: 5px;
  background: $Shade-100;
  //하단에  고정
  position: fixed;
  top: 175px;
  right: 50px;
  width: 33%;
  max-height: 80%;
  overflow-y: auto;
}

.importing-tabs .ant-tabs-content-holder {
}

.importing-btns {
  margin: 10px 0;
}

.reset-ao-btn {
  float: right;
  margin-bottom: 1rem;
}

// Answer Options
.ao-outer {
  margin: 20px 0;
  display: inline-block;
  float: right;
  width: 70%;
}

.ao-outer .ant-form-item:not(.ao-input .ant-form-item) {
  margin-bottom: 12px;
}

.ao-input .ant-form-item {
  margin-bottom: 0;
}

.ao-input {
  height: 40px;
  text-align: start;
}

.ao-input, .ao-input .ant-input {
  background-color: $Primary-100;
}

.ao-box-side {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;
}

.ao-box-outer .ant-form-item {
  margin-bottom: 0;
}

.ao-box-outer {
  background-color: $Primary-100;
  border: 1px dashed #7c7c7c;
  display: flex;
  margin: 5px 0;
}

.ao-box-inner {
  flex: 20;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 20px;
  min-height: 10vh;
}

.ao-input-outer {
  margin: 0 5px 5px 0;
}

.ao-input-outer input {
  text-align: center;
  border-radius: 10px !important;
}

.ao-topic-group {
  border: 2px dashed $Secondary-300;
  background: $Primary-100;
  padding: 5px;
  font-size: 14px;
  font-weight: 400;
}


// Psy Test Result
.result-card-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.result-card-title {
  font-size: 1.3em;
  font-weight: 600;
}

.result-title-input {
  margin: 12px 0;
  border-radius: 0;
  border: 1px dashed #7c7c7c;
  height: 40px;
  font-size: 1.1em;
  text-align: center;
  color: $Secondary-300;
}

.type-selector-btn {
  background: #fff;
  color: $Primary-200;
  border-radius: 25px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 700;
}

.type-selector-btn:hover {
  cursor: pointer;
}

// Image Upload
.ant-upload-wrapper.ant-upload-picture-card-wrapper {
  display: flex;
  justify-content: center;
}

.image-uploader-container {
  display: flex;
  margin-bottom: 20px;
}

.image-uploader.ant-upload-wrapper.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select {
  width: 208px;
  height: 208px;
}

.image-uploader img {
  height: 100%;
  object-fit: contain;
}

.image-uploader-guide {
  margin-top: 10px;
  font-size: 10px;
  color: $Shade-400;

}

.image-uploader-btn {
  margin: 0 20px;
}

// Decoration
.deco-outer {
  margin: 40px 0;
}

// Counseling
.ant-select-multiple .ant-select-selection-item {
  background: #13c2c2;
  color: #fff;
}

// Psy Test
.text-bubble {
  border: 2px solid $UserApp-50;
  background: $UserApp-10;
  border-radius: 5px;
  padding: 5px 10px;
}

// Convo Flow
.ao-next-qn {
  min-width: 30px;
  color: $Emphasis-300;
  font-weight: 600;
}

.ao-next-qn.hidden {
  display: none;
}

// CounselorMng
.counselor-form-group {
  margin: 10px 0 80px 0;
}

// PsyTest
.score-slider-outer {
  display: flex;
  margin-bottom: 20px;
}

.score-slider-outer .autoset-btns {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.score-slider-outer .autoset-btn {
  margin-bottom: 5px;
}

.score-slider-inner {
  width: 80%;
  padding: 30px;
  border: 1px solid #aaa;
  border-radius: 5px;
  cursor: pointer;
}

.score-slider-inner:hover {
  background: $Primary-50;
}

.score-slider-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.score-range-text {
  font-size: 1.2em;
  font-weight: 500;
  color: $Primary-200;
}

.common-card .score-range-text {
  margin: 0 10px;
}

.ant-slider.ant-slider-disabled .ant-slider-rail {
  //background-color: $Primary-200 !important;
}

.ant-slider.ant-slider-disabled .ant-slider-handle::after,
.ant-slider.ant-slider-disabled .ant-slider-mark-text,
.ant-slider.ant-slider-disabled {
  cursor: pointer !important
}

// Chat Preview
.chat-preview-container {
  display: flex;
}

.chat-preview-inner {
  width: 40%;
  margin-right: 25px;
}

.message-list-container, .chat-list-container {
  overflow-y: auto;
  background: $Primary-100;
  padding: 10px 30px;
  height: 80vh;
  box-shadow: 1px 1px 1px 1px #0003;
  border-radius: 5px;
}

.message-info-container {
  box-shadow: 1px 1px 1px 1px #0003;
  border-radius: 5px;
  opacity: 0.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 5px 10px;
}

.message-list {
}

.chat-list-container {
  background: #fff;
}

.message-input-container {
  width: 100%;
  display: flex;
  background: #fff;
  overflow: hidden;
  margin-top: 5px;
  box-shadow: 1px 1px 1px 1px #0003;
  border-radius: 5px;
  //transform: translateY(-100%);
}

.message-input {
  height: 50px;
}

.send-btn {
  margin-left: 5px;
  height: 4vh;
  width: 80px;
  background: $Shade-500;
}

.rce-mbox {
  padding: 10px;
}

.rce-mbox-title {
  cursor: auto;
}

.rce-mbox.rce-mbox--clear-notch {
  background: $UserApp-10;
  cursor: pointer;
  margin-left: 6vw;
}

.rce-mbox.rce-mbox-right {
  background: $UserApp-10;
  margin-bottom: 30px;
  margin-top: 30px;
}

.rce-mbox-right-notch {
  fill: $UserApp-10;
}

.chat-avatar-outer {
  box-shadow: 1px 1px 1px 1px #0003;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.date-text {
  letter-spacing: 0.5px;
}

/*Tabs*/
/*Psytest*/
.tab-title {
  padding: 0 100px;
  font-size: 1.2em;
}

.tab-bar-extra {
  margin-right: 10px;
}

.tab-content {
  background: #fff;
  padding: 30px 10px 10px 10px;
  border-radius: 8px 8px 0 0;
}

.ant-tabs-top > .ant-tabs-nav {
  margin-bottom: 0;
}

.psytest-qns {
  display: grid;
  grid-template-columns: 3fr 2fr;
  // gap: 20px;
}

.psytest-results .result-form-list {
  width: 80%;
}

.psytest-results .ant-card {
  margin-bottom: 20px;
}

/*답변옵션 일괄설정*/
.aoset {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #aaa;
}

.aoset > span {
  margin-right: 10px;
}

/*answer modal*/
.table-cell-seperated.left-align {
  text-align: start;
}

.table-cell-seperated:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}




