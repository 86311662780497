@import 'styles/color';

.login {
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 550px;
}

.login footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #20359b;
  letter-spacing: 0.02em;
  padding: 40px 0;
}

.login-outer {
  background-image: url('../assets/images/bg/myhome.png');
  //background-size: 170%;
  box-shadow: 0 0 5px #b3b3b3;
  width: 400px;
  height: 435px;
  overflow: hidden;
  box-sizing: border-box;
  padding: 0 60px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transform: translate(-50%, -50%);
  position: relative;
  left: 50%;
  top: 50%;
}

.login-outer .ver {
  color: #c5c5c5;
  position: absolute;
  top: 14px;
  right: 17px;
}

.login-outer .title-outer {
  height: 170px;
  width: 100%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-outer .title-outer .title-logo:hover {
  cursor: default;
}

.login-outer .title-outer .title-logo {
  background-image: url('../assets/images/bg/soulme_logo.png');
  background-size: cover;
  height: 80px;
  width: 100%;
}

.login-outer .title-outer .title {
  color: $Primary-200;
  font-size: 2.5em;
  font-weight: bold;
  //letter-spacing: 0.05em;
  line-height: 80%;
}

.login-outer .w .i {
  border-radius: 0;
  //display: block;
  border: none;
  border-bottom: 2px solid #dcdcdc;
  width: 100%;
  text-align: center;
  font-size: 1.3em;
  box-sizing: border-box;
  height: 45px;
  //margin-bottom: 20px;
}

// password input 별도로 추가
.login .ant-input-affix-wrapper > input.ant-input {
  text-align: center;
}

.login-outer .w .i::placeholder {
  color: #bfbfbf;
}

.login-outer .w .i:focus, .login-outer .w .i:hover {
  border-width: 2px;
  border-color: var($Primary-200);
  color: var($Primary-200);
  outline: none;
  transition: all 0.5s ease;
}

.login-outer .w .b {
  background: $Primary-300;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 70px;
  letter-spacing: 0.05em;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: 2em;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.5s ease;
  background-size: 100%;
}

/*.login-outer .w .b:hover, .login-outer .w .b:focus {
  background-size: 150%;
}*/

.login-outer:hover, .login-outer:focus {
  background-size: 150%;
}

/* login animation */
.login-outer .animation .left, .login-outer .animation .top, .login-outer .animation .right {
  position: absolute;
  background: $Primary-200;
}

.login-outer .animation .left {
  left: 0;
  bottom: 0;
  width: 1px;
  animation-duration: 1.5s;
  animation-name: ani_left;
  animation-timing-function: ease-out;
}

.login-outer .animation .top {
  left: 0;
  top: 0;
  height: 1px;
  animation-duration: 1.5s;
  animation-name: ani_top;
  animation-delay: 1s;
  animation-timing-function: ease-out;
}

.login-outer .animation .right {
  right: 0;
  top: 0;
  width: 1px;
  animation-duration: 1.5s;
  animation-name: ani_right;
  animation-delay: 2s;
  animation-timing-function: ease-out;
}

@media screen and (max-width: 500px) {
  .login-outer {
    width: 90%;
  }
}

@keyframes ani_left {
  from {
    height: 0;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    height: 100%;
    opacity: 0;
  }
}

@keyframes ani_top {
  from {
    width: 0px;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    width: 100%;
    opacity: 0;
  }
}

@keyframes ani_right {
  from {
    height: 0;
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    height: 100%;
    opacity: 0;
  }
}
